<template>
  <div class="everyday main-cnt" v-if="route.path == '/hotel/hprice/everyday'">
    <div class="title">每日房价</div>
    <div class="select-wp">
      <div class="select-left">
        <el-select
          v-if="false"
          v-model="area"
          placeholder="请选择大区"
          size="large"
        >
          <el-option
            v-for="item in areaOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-if="false"
          v-model="village"
          placeholder="请选择项目"
          size="large"
        >
          <el-option
            v-for="item in villageOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="hotel"
          placeholder="请选择酒店"
          size="large"
          @change="getInfoData"
        >
          <el-option
            v-for="item in hotelOptions"
            :key="item.h_id"
            :label="item.h_name"
            :value="item.h_id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="select-right">
        <el-button
          type="primary"
          round
          @click="showBulkPriceDialog"
          v-if="authData.indexOf('h_7GdYDiN1eGeDiFgLMSDe6CJiUGj9') != -1"
          >￥ 批量调价</el-button
        >
        <el-button
          round
          plain
          @click="showLogDialog"
          v-if="authData.indexOf('h_mNQPhwGqlel5vx2zJORoEf7eqphf') != -1"
          >调价记录</el-button
        >
      </div>
    </div>
    <div class="date-wp">
      <el-date-picker
        v-model="dateVal"
        type="date"
        :clearable="false"
        value-format="YYYY-MM-DD"
        placeholder="请选择日期"
        @change="dateChange"
      >
      </el-date-picker>
      <!-- <el-tabs v-model="dateVal" @tab-click="getInfoData">
        <el-tab-pane
          :label="item.label"
          :name="item.value"
          v-for="(item, index) in dateArr"
          :key="index"
        />
      </el-tabs> -->
      <div class="tabs-icon" @click="onArrowIcon('left')"><el-icon><ArrowLeft /></el-icon></div>
      <div class="scroll-container" ref="scrollContainer">
        <div class="content">
          <div v-for="(item, index) in dateArr" :key="index" :id="'tabs'+index" @click="handleClick(index)">
            <div class="tabs-text" :class="activeTab == index ? 'activeTabs' : ''">{{item.label}}</div>
          </div>
        </div>
      </div>
      <el-icon class="tabs-icon" @click="onArrowIcon('right')"><ArrowRight /></el-icon>
    </div>
    <div
      class="type-scroll"
      v-loading="isLoading"
      element-loading-text="数据加载中"
    >
      <el-scrollbar style="height: 100%" v-if="infoList.length">
        <div class="type-item" v-for="(item, index) in infoList" :key="index">
          <div class="type-header">
            <span class="name">{{ item.hrt_name }}</span>
            <!-- <span class="num"
              >共<span
                >&nbsp; <span>{{ item.hrt_number }}</span> &nbsp;</span
              >间</span
            > -->
          </div>
          <ul>
            <li
              :class="{ gray: itm.hrp_status != 1 || isPastDate }"
              v-for="(itm, i) in item.hotelRoomPackage"
              :key="i"
            >
              <div class="name">{{ itm.hp_name }}</div>
              <div
                class="residue"
                @click="showNumDialog(itm)"
                v-if="!isPastDate"
              >
                剩余&nbsp; <span>{{ itm.hrd_number }}</span
                >&nbsp; 间
              </div>
              <template v-if="isPastDate">
                <span class="price" style="cursor: text"
                  >￥{{ itm.hrp_price }}</span
                >
                <el-switch
                  :disabled="true"
                  v-model="isPastDate"
                  inline-prompt
                  active-text="售"
                  inactive-text="停"
                  :width="45"
                  :active-value="1"
                  :inactive-value="2"
                  :before-change="() => false"
              /></template>
              <template v-if="!isPastDate">
                <span class="price" @click="showPriceDialog(itm)"
                  >￥{{ itm.hrp_price }}</span
                >
                <template
                  v-if="
                    authData.indexOf('h_jcbSCwUrlLGEXzm0rqR22rbbaxNx') != -1
                  "
                >
                  <el-popconfirm
                    :title="`确定要${
                      itm.hrp_status == 1 ? '关闭' : '开启'
                    }该套餐的销售状态吗?`"
                    @confirm="switchConfirm(itm)"
                  >
                    <template #reference>
                      <el-switch
                        v-model="itm.hrp_status"
                        inline-prompt
                        active-text="售"
                        inactive-text="停"
                        :width="45"
                        :active-value="1"
                        :inactive-value="2"
                        :before-change="() => false"
                      />
                    </template>
                  </el-popconfirm>
                </template>
                <template v-else>
                  <el-switch
                    :disabled="true"
                    v-model="itm.hrp_status"
                    inline-prompt
                    active-text="售"
                    inactive-text="停"
                    :width="45"
                    :active-value="1"
                    :inactive-value="2"
                    :before-change="() => false"
                  />
                </template>
              </template>
            </li>
          </ul>
        </div>
      </el-scrollbar>
    </div>

    <!-- 房量调整 -->
    <w-dialog
      class="adjust-dialog"
      ref="numDialog"
      title="调整房量"
      width="35%"
      btnWidth="140px"
      @wConfirm="numConfirm"
    >
      <h3>
        {{ currentData && currentData.hp_name ? currentData.hp_name : "" }}
      </h3>
      <span class="tip"
        >{{ dateVal.replace(/-/g, "/") }}&nbsp;当日余房<span>
          &nbsp;{{ currentData.hrd_number }}&nbsp; </span
        >间，调整为
      </span>
      <el-input v-model="roomNum" placeholder="请输入余房数量" />
    </w-dialog>

    <!-- 房价调整 -->
    <w-dialog
      class="adjust-dialog"
      ref="priceDialog"
      title="调整房价"
      width="35%"
      btnWidth="140px"
      @wConfirm="priceConfirm"
    >
      <h3>
        {{ currentData && currentData.hp_name ? currentData.hp_name : "" }}
      </h3>
      <span class="tip"
        >{{ dateVal.replace(/-/g, "/") }}&nbsp;当日售价<span>
          &nbsp;￥{{ currentData.hrp_price }}&nbsp; </span
        >元，调整为
      </span>
      <el-input v-model="selPrice" placeholder="请输入新房价" />
    </w-dialog>

    <!-- 批量调价弹框 -->
    <w-dialog
      ref="bulkPriceDialog"
      class="bulk-price-dialog"
      title="批量调价"
      width="55%"
      btnWidth="140px"
      @wConfirm="patchConfirm"
    >
      <el-form
        class="bulk-price-form"
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        labelPosition="top"
      >
        <el-form-item label="选择酒店" prop="hotel">
          <el-select
            v-model="ruleForm.hotel"
            clearable
            placeholder="请选择酒店"
            @change="batchHotelChange"
          >
            <el-option
              :label="item.h_name"
              :value="item.h_id"
              v-for="item in hotelOptions"
              :key="item.h_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="选择房型" prop="type">
          <el-select
            v-model="ruleForm.type"
            clearable
            placeholder="请选择房型"
            @change="batchTypeChange"
          >
            <el-option
              :label="item.hrt_name"
              :value="item.hrt_id"
              v-for="item in batchTypeOptions"
              :key="item.hrt_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择套餐" prop="setmeal">
          <el-select
            v-model="ruleForm.setmeal"
            clearable
            placeholder="请选择套餐"
          >
            <el-option
              :label="item.hp_name"
              :value="item.hp_id"
              v-for="item in comboOptions"
              :key="item.hp_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="调价日期" prop="timeRange">
          <el-date-picker
            v-model="ruleForm.timeRange"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="YYYY-MM-DD"
            :disabled-date="disabledDate"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item class="price-item" label="" prop="price">
          <span class="tip"
            >原价格<span>&nbsp;{{ minPrice }}~{{ maxPrice }}&nbsp;</span
            >元，整体调整为</span
          >
          <el-input
            v-model="ruleForm.price"
            placeholder="请输入新价格"
            @change="priceChange"
          ></el-input>
        </el-form-item>
      </el-form>
    </w-dialog>

    <!-- 调价记录弹框 -->
    <w-dialog
      class="logDialog"
      ref="logDialog"
      title="调价记录"
      width="70%"
      btnWidth="140px"
      top="8vh"
      :hideFooter="true"
    >
      <common-table
        ref="logTable"
        tableHeight="63vh"
        :ischeck="false"
        :ispaging="true"
        :apiName="HotelApi.hotelDailyLog"
        :filters="filters"
        :columns="tableColumns"
        @hotelChange="hotelChange"
        @typeChange="typeChange"
      ></common-table>
    </w-dialog>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted, nextTick, watch, computed } from "vue";
import * as dayjs from "dayjs";
import { BasicApi, HotelApi } from "@/plugins/api.js";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { ArrowLeft, ArrowRight } from "@element-plus/icons-vue";

const store = useStore();
const menuTokens = computed(() => store.state.menuToken.menuTokens);
const authData = ref([]);
const route = useRoute();

const isPastDate = computed(() => {
  return dayjs(dateVal.value).isBefore(dayjs().subtract(1, "day"));
});
watch(
  () => menuTokens.value,
  (data) => {
    if (data.length) {
      authData.value = data;
    }
  },
  {
    deep: true,
    immediate: true,
  }
);
/** 大区 */
const area = ref("");
const areaOptions = [
  {
    value: 1,
    label: "一区",
  },
  {
    value: 2,
    label: "二区",
  },
];

/** 项目 */
const village = ref("");
const villageOptions = [];

/** 酒店 */
const hotel = ref("");
let hotelOptions = ref([]);
/** 获取酒店列表数据 */
const getHotelData = () => {
  BasicApi.getHotelByProject().then((res) => {
    if (res.Code === 200) {
      hotelOptions.value = res.Data ? res.Data : [];
      hotel.value = res.Data[0].h_id;
      getInfoData();
    } else {
      let msg = res.Message ? res.Message : "获取酒店数据失败！";
      ElMessage.error(msg);
    }
  });
};

/** 选择日期 */
const dateVal = ref("");

/** 日期选择列表 */
const dateArr = ref([]);

/** 获取两个时间之间的日期数组的方法 */
const getAllDateCN = (stime, etime) => {
  let date_all = [];
  let i = 0;
  while (etime.getTime() - stime.getTime() >= 0) {
    const y = stime.getFullYear();
    const m =
      stime.getMonth() + 1 >= 10
        ? stime.getMonth() + 1
        : "0" + (stime.getMonth() + 1);
    const d = stime.getDate() >= 10 ? stime.getDate() : "0" + stime.getDate();
    const day = stime.getDay();
    let day_tex = "";
    switch (day) {
      case 0:
        day_tex = "日";
        break;
      case 1:
        day_tex = "一";
        break;
      case 2:
        day_tex = "二";
        break;
      case 3:
        day_tex = "三";
        break;
      case 4:
        day_tex = "四";
        break;
      case 5:
        day_tex = "五";
        break;
      case 6:
        day_tex = "六";
        break;
    }
    const obj = {
      value: y + "-" + m + "-" + d,
      label: m + "-" + d + " " + day_tex,
    };
    date_all[i] = obj;
    stime.setDate(stime.getDate() + 1);
    i += 1;
  }
  return date_all;
};

/** 日期选择更改处理 */
const dateChange = (value) => {
  const ny = value.split("-")[0];
  const oy = dateArr.value[0].value.split("-")[0];
  if (ny !== oy) {
    const stime = new Date(ny + "-01-01");
    const etime = new Date(ny + "-12-31");
    dateArr.value = getAllDateCN(stime, etime);
  }
  getInfoData();
  getTabIndex();
};
/** 详情列表数据 */
const infoList = ref([]);
/** 是否正在加载列表数据 */
const isLoading = ref(false);
/** 当前数据 */
const currentData = ref(null);
/** 获取某天详情列表 */
const getInfoData = () => {
  isLoading.value = true;
  let data = {
    hrd_hid: hotel.value,
    hrd_date: dateVal.value,
  };
  HotelApi.hotelDailyInfo(data).then((res) => {
    if (res.Code === 200) {
      infoList.value = res.Data ? res.Data : [];
    } else {
      let msg = res.Message ? res.Message : "获取数据失败！";
      ElMessage.error(msg);
    }
    isLoading.value = false;
  });
};

/** 房量调整弹框 */
const numDialog = ref(null);
/** 房间数量 */
const roomNum = ref("");
/** 弹出房量调整弹框 */
const showNumDialog = (obj) => {
  if (authData.value.indexOf("h_ZgiYoz3CNxOEWMlQFexkB5fd08vZ") != -1) {
    currentData.value = obj;
    roomNum.value = "";
    numDialog.value.show();
  }
};
/**房量调整确认 */
const numConfirm = () => {
  if (roomNum.value === 0 || roomNum.value === "0" || roomNum.value > 0) {
    numDialog.value.isLoading = true;
    let data = {
      hrd_id: currentData.value.hrd_id,
      hrd_number: roomNum.value,
    };
    HotelApi.editHotelDailyNumber(data).then((res) => {
      numDialog.value.isLoading = false;
      if (res.Code === 200) {
        ElMessage.success("余房数量修改成功！");
        numDialog.value.close();
        // 重新获取列表数据
        getInfoData();
      } else {
        let msg = res.Message ? res.Message : "余房数量修改失败！";
        ElMessage.error(msg);
      }
    });
  } else {
    ElMessage.warning("请输入余房数量");
  }
};

/** 房价调整弹框 */
const priceDialog = ref(null);
/** 当日售价 */
const selPrice = ref("");
/** 弹出房价调整弹框 */
const showPriceDialog = (obj) => {
  if (authData.value.indexOf("h_jcbSCwUrlLGEXzm0rqR4TrbbaxNx") != -1) {
    currentData.value = obj;
    selPrice.value = "";
    priceDialog.value.show();
  }
};
/** 房价调整确认 */
const priceConfirm = () => {
  if (selPrice.value === 0 || selPrice.value === "0" || selPrice.value > 0) {
    priceDialog.value.isLoading = true;
    let data = {
      hrp_id: currentData.value.hrp_id,
      hrp_price: selPrice.value,
    };
    HotelApi.editHotelDailyPrice(data).then((res) => {
      priceDialog.value.isLoading = false;
      if (res.Code === 200) {
        ElMessage.success("房价修改成功！");
        priceDialog.value.close();
        // 重新获取列表数据
        getInfoData();
      } else {
        let msg = res.Message ? res.Message : "房价修改失败！";
        ElMessage.error(msg);
      }
    });
  } else {
    ElMessage.warning("请输入新房价");
  }
};
/** 确认改变状态处理 */
const switchConfirm = (obj) => {
  let data = {
    hrp_id: obj.hrp_id,
    hrp_status: obj.hrp_status == 1 ? 2 : 1,
  };
  HotelApi.setHotelDailyStatus(data).then((res) => {
    if (res.Code === 200) {
      ElMessage.success("销售状态修改成功！");
      // 重新获取列表数据
      getInfoData();
    } else {
      let msg = res.Message ? res.Message : "销售状态修改失败！";
      ElMessage.error(msg);
    }
  });
};

/** 批量调价弹框 */
const bulkPriceDialog = ref(null);
/** 表单对象 */
const ruleFormRef = ref(null);
/** 表单数据对象 */
const ruleForm = reactive({
  hotel: "", // 酒店
  type: "", // 房型
  setmeal: "", // 套餐
  timeRange: [], // 调价日期
  price: "", // 价格
});
/** 表单规则对象 */
const rules = reactive({
  hotel: [
    {
      required: true,
      message: "请选择酒店",
      trigger: "change",
    },
  ],
  type: [
    {
      required: true,
      message: "请选择房型",
      trigger: "change",
    },
  ],
  setmeal: [
    {
      required: true,
      message: "请选择套餐",
      trigger: "change",
    },
  ],
  timeRange: [
    {
      required: true,
      message: "请选择调价日期",
      trigger: "change",
    },
  ],
  price: [
    {
      required: true,
      message: "请输入新价格",
      trigger: "blur",
    },
  ],
});
/** 最小价格 */
const minPrice = ref(0);
/** 最大价格 */
const maxPrice = ref(0);
/** 监听套餐改变 */
watch(
  () => ruleForm.setmeal,
  (value) => {
    ruleForm.price = "";
    if (
      value &&
      ruleForm.timeRange &&
      ruleForm.timeRange[0] &&
      ruleForm.timeRange[1]
    ) {
      getHotelDailyPrice();
    } else {
      minPrice.value = 0;
      maxPrice.value = 0;
    }
  }
);
/** 监听调价日期 */
watch(
  () => ruleForm.timeRange,
  (value) => {
    ruleForm.price = "";
    if (value && ruleForm.setmeal) {
      getHotelDailyPrice();
    } else {
      minPrice.value = 0;
      maxPrice.value = 0;
    }
  }
);
/** 获取 每日房价-批量调价-获取价格范围 */
const getHotelDailyPrice = () => {
  let data = {
    hp_id: ruleForm.setmeal,
    start_time: ruleForm.timeRange[0],
    end_time: ruleForm.timeRange[1],
  };
  BasicApi.getHotelDailyPrice(data).then((res) => {
    if (res.Code === 200) {
      minPrice.value = res.Data.min_price;
      maxPrice.value = res.Data.max_price;
    } else {
      let msg = res.Message ? res.Message : "获取价格范围数据失败！";
      ElMessage.error(msg);
    }
  });
};
/** 价格改变处理 */
const priceChange = (value) => {
  if (Number(value) <= 0) {
    ruleForm.price = "";
    ElMessage.warning(`价格不能为0或负数`);
  }
};
/** 日期禁用 */
const disabledDate = (value) => {
  return value.getTime() < dayjs(dayjs().format("YYYY-MM-DD")).valueOf();
};
/** 弹出批量调价弹框 */
const showBulkPriceDialog = () => {
  ruleForm.hotel = hotel.value; // 酒店
  ruleForm.type = ""; // 房型
  ruleForm.setmeal = ""; // 套餐
  ruleForm.timeRange = []; // 调价日期
  ruleForm.price = ""; // 价格
  batchTypeOptions.value = [];
  comboOptions.value = [];
  bulkPriceDialog.value.show();
  if (hotel.value) {
    BasicApi.getTypeByHotel({ hid: hotel.value }).then((res) => {
      if (res.Code === 200) {
        batchTypeOptions.value = res.Data ? res.Data : [];
      } else {
        let msg = res.Message ? res.Message : "获取户型数据失败！";
        ElMessage.error(msg);
      }
    });
  }
};
/** 酒店更改 */
const batchHotelChange = (value) => {
  ruleForm.type = "";
  batchTypeOptions.value = [];
  ruleForm.setmeal = "";
  comboOptions.value = [];
  if (value) {
    BasicApi.getTypeByHotel({ hid: value }).then((res) => {
      if (res.Code === 200) {
        batchTypeOptions.value = res.Data ? res.Data : [];
      } else {
        let msg = res.Message ? res.Message : "获取户型数据失败！";
        ElMessage.error(msg);
      }
    });
  }
};
/** 户型列表 */
const batchTypeOptions = ref([]);
/** 户型更改 */
const batchTypeChange = (value) => {
  ruleForm.setmeal = "";
  comboOptions.value = [];
  if (value) {
    BasicApi.getComboByHotel({ hrt_id: value }).then((res) => {
      if (res.Code === 200) {
        comboOptions.value = res.Data ? res.Data : [];
      } else {
        let msg = res.Message ? res.Message : "获取套餐数据失败！";
        ElMessage.error(msg);
      }
    });
  }
};
/** 套餐列表 */
const comboOptions = ref([]);
/** 批量调价确认 */
const patchConfirm = () => {
  ruleFormRef.value.validate((valid) => {
    if (valid) {
      bulkPriceDialog.value.isLoading = true;
      let data = {
        hp_id: ruleForm.setmeal,
        price: ruleForm.price,
        start_time: ruleForm.timeRange[0],
        end_time: ruleForm.timeRange[1],
      };
      HotelApi.batchHotelDailyPrice(data).then((res) => {
        bulkPriceDialog.value.isLoading = false;
        if (res.Code === 200) {
          ElMessage.success("批量修改成功！");
          bulkPriceDialog.value.close();
          // 重新获取列表数据
          getInfoData();
        } else {
          let msg = res.Message ? res.Message : "批量修改失败!";
          ElMessage.error(msg);
        }
      });
    }
  });
};

/** 调价记录弹框 */
const logDialog = ref(null);
/** 筛选条件列表 */
const filters = ref([
  {
    filterType: "select",
    name: "hid",
    value: "",
    placeholder: "请选择酒店",
    options: [],
    lab: "h_name",
    val: "h_id",
    action: "hotelChange",
  },
  {
    filterType: "select",
    name: "hrt_id",
    value: "",
    placeholder: "请选择房型",
    options: [],
    lab: "hrt_name",
    val: "hrt_id",
    action: "typeChange",
  },
  {
    filterType: "select",
    name: "hp_id",
    value: "",
    placeholder: "请选择套餐",
    options: [],
    lab: "hp_name",
    val: "hp_id",
  },
  {
    filterType: "date",
    name: "start_time",
    name2: "end_time",
    value: [],
    placeholder: "请选择日期范围",
    type: "daterange",
  },
]);
/** 表格配置数据 */
const tableColumns = ref([
  {
    prop: "hotelRoomPackage",
    prop2: "hotelRoomDaily",
    prop3: "project",
    prop4: "p_name",
    label: "所属项目",
    minWidth: 120,
    color: "--text-third-color",
  },
  {
    prop: "hotelRoomPackage",
    prop2: "hotelRoomDaily",
    prop3: "hotel",
    prop4: "h_name",
    label: "所属酒店",
    minWidth: 120,
    color: "--text-color",
  },
  {
    prop: "hotelRoomPackage",
    prop2: "hotelRoomDaily",
    prop3: "hotelRoomType",
    prop4: "hrt_name",
    label: "调价房型",
    minWidth: 120,
    color: "--text-third-color",
  },
  {
    prop: "hotelRoomPackage",
    prop2: "hotelPackage",
    prop3: "hp_name",
    label: "调价套餐",
    minWidth: 120,
    color: "--text-color",
  },
  {
    prop: "hrpl_before_price",
    label: "原价格",
    minWidth: 100,
    color: "--text-third-color",
  },
  {
    prop: "hrpl_after_price",
    label: "调后价格",
    minWidth: 100,
    color: "--theme-color",
  },
  {
    prop: "hrpl_date_timestamp",
    label: "调整日期",
    minWidth: 120,
    color: "--text-third-color",
  },
  {
    prop: "creator",
    prop2: "u_name",
    label: "操作人",
    minWidth: 120,
    color: "--text-third-color",
  },
  {
    prop: "hrpl_ctime",
    label: "操作时间",
    minWidth: 160,
    color: "--text-third-color",
  },
]);
/** 调价记录表格 */
const logTable = ref(null);
/** 弹出调价记录弹框 */
const showLogDialog = () => {
  logDialog.value.show();
  filters.value[0].value = hotel.value;
  filters.value[0].options = hotelOptions.value;
  if (hotel.value) {
    getRoomTypeData();
  }
  nextTick(() => {
    logTable.value.tableLoad();
  });
};
/** 酒店选择更改 */
const hotelChange = (obj) => {
  filters.value[1].value = "";
  filters.value[1].options = [];
  filters.value[2].value = "";
  filters.value[2].options = [];
  logTable.value.tableLoad();
  if (obj.value) {
    getRoomTypeData();
  }
};
/** 获取户型列表数据 */
const getRoomTypeData = () => {
  BasicApi.getTypeByHotel({ hid: filters.value[0].value }).then((res) => {
    if (res.Code === 200) {
      filters.value[1].options = res.Data;
    } else {
      let msg = res.Message ? res.Message : "获取户型数据失败！";
      ElMessage.error(msg);
    }
  });
};
/** 户型选择更改 */
const typeChange = (obj) => {
  filters.value[2].value = "";
  filters.value[2].options = [];
  logTable.value.tableLoad();
  if (obj.value) {
    getComboData();
  }
};
/** 获取套餐列表数据 */
const getComboData = () => {
  BasicApi.getComboByHotel({ hrt_id: filters.value[1].value }).then((res) => {
    if (res.Code === 200) {
      filters.value[2].options = res.Data;
    } else {
      let msg = res.Message ? res.Message : "获取套餐数据失败！";
      ElMessage.error(msg);
    }
  });
};

const activeTab = ref(0);  // 日期选中下标
const scrollContainer = ref(null);  // 滚动条
/**
 * 
 * 选择日期
 * 
 * **/
const handleClick = (i)=> {
  activeTab.value = i;
  dateVal.value = dateArr.value[i].value;
  scrollToElement(i);
  getInfoData();
}
/**
 * 
 * 获取点击日期下标
 * 
 * **/
const getTabIndex = () => {
  let activeIndex = dateArr.value.findIndex(function(item) {
    return item.value == dateVal.value;
  });
  activeTab.value = activeIndex;
  scrollToElement(activeIndex);
}
/**
 * 
 * 滚动条位置
 * 
 * **/
const scrollToElement=(elementId)=> {
  const element = document.getElementById('tabs'+elementId);
  iconNum.value = 0;
  if (element && scrollContainer.value) {
    const scrollLeft = element.offsetLeft - scrollContainer.value.offsetLeft;
    scrollContainer.value.scrollTo({
      left: scrollLeft,
      behavior: 'smooth',
    });
  }
}
// 日期左侧图标点击次数
const iconNum = ref(0);
/**
 * 
 * 点击日期图标
 * 
 * **/
const onArrowIcon = (type) => {
  // 当前日期下标
  let activeIndex = dateArr.value.findIndex(function(item) {
    return item.value == dateVal.value;
  });
  if(type == 'left') {
    // 点击左侧次数加一
    iconNum.value++;
  } else {
    // 点击右侧次数减一
    iconNum.value--;
  }
  // 根据当前日期位置获取每次移动位置
  const element = document.getElementById('tabs' + (activeIndex - 13 * iconNum.value));
  // scrollContainer.value.offsetLeft：当前滚动条位置
  const scrollLeft = element.offsetLeft - scrollContainer.value.offsetLeft;
  scrollContainer.value.scrollTo({
      left: scrollLeft,
      behavior: 'smooth',
    });
}
/** 生命周期 */
onMounted(() => {
  const year = dayjs().year();
  const stime = new Date(year + "-01-01");
  const etime = new Date(year + "-12-31");
  dateArr.value = getAllDateCN(stime, etime);
  iconNum.value = 0;
  nextTick(() => {
    setTimeout(() => {
      dateVal.value = dayjs().format("YYYY-MM-DD");
      getHotelData();
      getTabIndex();
    });
  });
});
</script>

<style lang="scss">
.everyday {
  font-family: "Source Han Sans CN";
  .date-wp {
    height: 40px;
    background-color: var(--theme-bg-color);
    margin: 0 20px 20px;
    display: flex;
    align-items: center;
     /* 针对Webkit浏览器，隐藏滚动条 */
    .scroll-container::-webkit-scrollbar {
      display: none;
    }
    .scroll-container {
      width: calc(100% - 280px); /* 容器宽度 */
      height: 40px; /* 容器高度 */
      overflow-x: auto; /* 开启水平滚动条 */
      line-height: 40px;
    }
    .activeTabs {
      color: var(--theme-color) !important;
    }
    .content {
      width: 1000px; /* 内容宽度，足够长，以便产生滚动 */
      height: 30px; /* 内容高度 */
      display: flex;
    }
    .tabs-text {
      width: 100px;
      cursor: pointer;
    }
    .tabs-icon {
      cursor: pointer;
      margin: 0 10px;
    }
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 192px;
    }
    .el-date-editor {
      .el-input__inner {
        background-color: var(--search-bg-color);
        color: var(--theme-color);
        padding-left: 40px;
      }
      .el-input__prefix {
        .el-icon {
          color: var(--theme-color);
          font-size: 18px;
        }
      }
    }
    .el-tabs {
      width: calc(100% - 260px);
      margin-left: 30px;
      .el-tabs__header {
        margin: 0;
        .el-tabs__item.is-active,
        .el-tabs__item:hover {
          color: var(--theme-color);
        }
        .el-tabs__nav-wrap {
          padding: 0 30px;
          .el-tabs__nav-prev,
          .el-tabs__nav-next {
            font-size: 18px;
          }
          .el-tabs__nav-scroll {
            .el-tabs__nav {
              .el-tabs__active-bar {
                background-color: var(--theme-color);
              }
            }
          }
        }
        .el-tabs__nav-wrap::after {
          display: none;
        }
      }
      .el-tabs__content {
        display: none;
      }
    }
  }
  .type-scroll {
    height: calc(100vh - 320px);
    width: 100%;
    .el-scrollbar__view {
      min-height: 100%;
      display: flex;
      box-sizing: border-box;
      .type-item {
        padding: 0 30px 20px;
        border-left: 1px solid #e1e4eb;
        .type-header {
          display: flex;
          margin-bottom: 8px;
          .name {
            font-size: 18px;
            font-weight: bold;
            color: var(--text-color);
            flex: 1;
          }

          .num {
            font-size: 14px;
            color: var(--text-third-color);
            cursor: pointer;
            > span {
              color: var(--theme-color);
            }
          }
        }
        > ul {
          height: calc(100% - 28px);
          li {
            width: 200px;
            height: 120px;
            background-color: var(--search-bg-color);
            border-radius: 4px;
            margin-bottom: 20px;
            padding: 14px 16px 16px 16px;
            position: relative;
            .name {
              font-size: 16px;
              line-height: 20px;
              font-weight: bold;
              color: var(--text-color);
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
            }
            .residue {
              font-size: 14px;
              font-weight: 400;
              color: var(--text-third-color);
              margin-top: 6px;
              span {
                color: var(--theme-color);
                cursor: pointer;
              }
            }
            .price {
              font-size: 14px;
              line-height: 20px;
              color: var(--theme-color);
              position: absolute;
              left: 15px;
              bottom: 13px;
              cursor: pointer;
            }
            .el-switch {
              position: absolute;
              right: 15px;
              bottom: 13px;
            }
            .el-switch.is-checked {
              .el-switch__core {
                border-color: var(--theme-color);
                background-color: var(--theme-color);
              }
            }
          }
          li:last-child {
            margin-bottom: 0;
          }
          li.gray {
            background-color: var(--search-uncheck-bg-color);
            .price {
              color: var(--text-third-color);
            }
          }
        }
      }
      .type-item:first-child {
        border: none;
        padding-left: 20px;
      }
      .type-item:last-child {
        padding-right: 20px;
      }
    }
  }
  .adjust-dialog {
    .el-dialog {
      .el-dialog__body {
        h3 {
          font-size: 15px;
          color: var(--text-color);
          font-weight: 700;
          margin-bottom: 25px;
        }
        .tip {
          font-size: 14px;
          color: var(--text-second-color);
          display: inline-block;
          margin-bottom: 20px;
          margin-right: 10px;
          > span {
            color: var(--text-color);
          }
        }
        .el-input {
          display: inline-block;
          width: 192px;
          margin-bottom: 20px;
        }
      }
    }
  }
  .bulk-price-dialog {
    .el-dialog {
      min-width: 840px;
      .el-dialog__body {
        padding: 0 15px 50px;
        .bulk-price-form {
          display: flex;
          flex-wrap: wrap;
          .el-form-item {
            width: 33.3%;
            padding: 25px 15px 0;
            margin-bottom: 0;
            .el-form-item__content {
              .el-select {
                width: 100%;
                .el-input {
                  width: 100%;
                }
              }
              .el-date-editor {
                width: 100%;
                .el-range-input {
                  background-color: transparent;
                }
              }
            }
          }
          .el-form-item.price-item {
            padding-top: 40px;
            width: 100%;
            .tip {
              margin-right: 15px;
              > span {
                font-weight: 700;
              }
            }
            .el-input {
              display: inline-block;
              width: 192px;
            }
          }
          .el-divider {
            margin: 30px 0 0 15px;
            width: calc(100% - 30px);
          }
        }
      }
    }
  }
  .logDialog {
    .el-dialog {
      .el-dialog__body {
        padding: 20px;
      }
    }
  }
}
</style>
